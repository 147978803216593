<template>
  <div class="container monitor">
    <div class="screen">
      <div class="menu">
        <div
          v-for="item in btnList"
          :key="item.id"
          class="menu-btn"
          @mouseover="changeHoverId(item.id)"
          @mouseout="resetHoverId()"
          @click="selectIcon(item.id, item.label)"
        >
          <!-- <div class="menu-btn-tip">{{item.text}}</div> -->
          <img
            v-show="isSelected != item.id && isHover != item.id"
            :src="item.src1"
            alt=""
          />
          <img
            v-show="isSelected == item.id || isHover == item.id"
            :src="item.src2"
            alt=""
          />
        </div>
        <div
          class="select"
          :class="{ 'select--expanded': visible }"
          v-click-outside="onClickOutside"
          id="dropdown"
        >
          <div
            class="select-input"
            @click="onClick"
          >
            <input
              placeholder="监控列表"
              readonly
            />
            <img
              src="@/assets/image/icon/dropdown.png"
              alt=""
            />
          </div>
          <div
            v-show="visible"
            class="dropdown-menu"
          >
            <tree
              :data="projTree"
              filterable
              ref="tree"
              check-on-click-node
              :default-expand-all="true"
              :lazy="false"
              :empty-text="emptyText"
              :show-checkbox="true"
              :defaultCheckedKeys="idList"
              @playVideo="playVideo"
              @onClickOutside="onClickOutside"
            />
          </div>
          <div class="border lt"></div>
          <div class="border rt"></div>
        </div>
      </div>
      <div
        v-if="links.length == 0"
        class="wrap-empty"
      >
        <img src="@/assets/image/monitoring.gif" />
      </div>
      <div
        v-else
        class="wrap"
        ref="videoWrap"
      >
        <div
          v-for="(item, index) in layout"
          :key="index"
          class="wrap-item"
        >
          <!-- <ezui :ref="item.name" v-if="item.url != null"  :name ="item.name" :url="item.url" :accessToken="accessToken" :height="item.height1" :width="item.width1"></ezui> -->
          <div
            v-if="item.url && item.url.length > 0"
            :key="item.url"
            :style="{ width: item.width1, height: item.height1 }"
          >
            <div class="camera-title" v-if="item.url" :title="item.cameraName">设备：{{ item.cameraName }}</div>
            <hikvision-video
              :token="token"
              ref="liveVideo"
              :item="item"
              :typeNum="isSelected"
              :cameraIndexCode="item.cameraIndexCode"
              :channelNo="item.channelNo"
              :quality="item.quality"
              @reset="onReset(item)"
              @save="onSave(item)"
            ></hikvision-video>
          </div>
          <div
            v-else
            :style="{ width: item.width1, height: item.height1 }"
            class="empty"
          >
            <img src="@/assets/image/monitoring.gif" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//未选中
import icon4 from "@/assets/image/icon/icon4.png"
import icon9 from "@/assets/image/icon/icon9.png"
import icon16 from "@/assets/image/icon/icon16.png"
// 已选择
import icon4Selected from "@/assets/image/icon/icon4-selected.png"
import icon9Selected from "@/assets/image/icon/icon9-selected.png"
import icon16Selected from "@/assets/image/icon/icon16-selected.png"
import Tree from "./tree"
import { getProjectCamera, fetchGetAllCameraUrl, fetchControlStart, fetchControlEnd, fetchMovePreset, fetchAddPreset, getTokenCamera } from "@/api"
import ClickOutside from "vue-click-outside"
// import Ezui from '../../view/Ezui'
import { mapGetters } from "vuex"

export default {
  components: {
    // Ezui,
    Tree,
  },
  props: {
    projId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      token: "",
      visible: false,
      idList: [],
      monitorList: [],
      minWidth: 212,
      emptyText: "无相应监控设备",
      checkAll: false,
      isIndeterminate: true,
      isSelected: 4,
      label: 2,
      layout: [],
      isHover: "",
      btnList: [
        {
          id: 4,
          label: 2,
          text: "4屏",
          src1: icon4,
          src2: icon4Selected,
        },
        {
          id: 9,
          label: 3,
          text: "9屏",
          src1: icon9,
          src2: icon9Selected,
        },
        {
          id: 16,
          label: 4,
          text: "16屏",
          src1: icon16,
          src2: icon16Selected,
        },
      ],
      accessToken: "at.0527zat52iym8ih4d0mv7yypc506x84w-73skru3h9t-13e3i66-9ul6euxzz",
      url: "",
      links: [],
      monitor: false,
      width: 1858, //高保真
      height: 865,
      url1: "",
      width1: 0,
      height1: 0,
    }
  },
  mounted() {
    this.getToken()
  },
  created() {
    this.selectIcon(4, 2)
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          //获取视频设备列表
          return getProjectCamera(val).then((res) => {
            this.projTree = [res.data]
          })
        }
      },
    },
  },
  methods: {
    getToken() {
      getTokenCamera().then((data) => {
        this.token = data.data.accessToken
      })
    },
    //请求视频列表
    playVideo(list) {
      this.idList = []
      if (list && list.length) {
        if (list.length >= this.projTree[0].children.length) {
          list.forEach((item, index) => {
            if (item.children == null) {
              let obj = { ...item }
              // obj.protocol = 'https:' == document.location.protocol ? 'hlss' : 'hls'
              delete obj.cameraStatus
              this.idList.push(obj)
            }
          })
          //   let query=JSON.stringify({cameraIdList:this.idList})
          let query = JSON.stringify(this.idList)
          fetchGetAllCameraUrl(query).then((data) => {
            if (data) {
              this.monitor = true
              this.links = []
              data.data.forEach((item) => {
                if (item) {
                  this.links.push(item)
                }
              })
              this.selectIcon(this.isSelected, this.label)
            } else {
              this.monitor = false
            }
          })
        } else {
          list.forEach((item) => {
            let obj = { ...item }
            obj.protocol = "https:" == document.location.protocol ? "hlss" : "hls"

            delete obj.cameraStatus

            this.idList.push(obj)
          })
          let query = JSON.stringify(this.idList)
          fetchGetAllCameraUrl(query).then((data) => {
            if (data) {
              this.monitor = true
              this.links = []
              data.data.forEach((item) => {
                if (item.cameraIndexCode) {
                  this.links.push(item)
                }
              })
              this.selectIcon(this.isSelected, this.label)
            } else {
              this.monitor = false
            }
          })
        }
      } else if (!list || !list.length) {
        this.monitor = false
      }
    },
    //更改屏数
    changeHoverId(id) {
      this.isHover = id
    },
    resetHoverId() {
      this.isHover = 0
    },
    selectIcon(id, label) {
      this.isSelected = id
      this.label = label
      this.width1 = this.width / label
      this.height1 = this.height / label
      var list = []
      for (var i = 0; i < this.isSelected; i++) {
        list.push({
          id: i < this.links.length ? this.links[i].id : null,
          url: i < this.links.length ? this.links[i].url : null,
          cameraName: i < this.links.length ? this.links[i].cameraName : null,
          // 'url': null,
          name: i,
          width1: this.width1 + "px",
          height1: this.height1 + "px",
          platform: i < this.links.length ? this.links[i].platform : null,
          cameraType: i < this.links.length ? this.links[i].cameraType : null,
          cameraIndexCode: i < this.links.length ? this.links[i].cameraIndexCode : null,
          channelNo: i < this.links.length ? this.links[i].channelNo : null,
          quality: i < this.links.length ? this.links[i].quality : null,
        })
      }
      this.layout = list
    },
    //裁剪视频窗口遮罩
    toHideWnd() {},
    //恢复视频窗口遮罩
    toShowWnd() {},
    onClick() {
      if (this.projTree) {
        this.visible = !this.visible
        if (this.visible) {
          this.toHideWnd()
        } else {
          this.toShowWnd()
        }
      }
    },
    onClickOutside() {
      this.isSelected = this.isSelected
      this.visible = false
      this.toShowWnd()
    },

    onControlStart: function (key, { id }, index) {
      fetchControlStart(id, key)
        .then(() => {
          this.$refs[`liveVideo_${index}`][0].openMessage("操作成功，由于网络延迟，请稍等~")
        })
        .catch((err) => {
          console.error(err)
        })
    },
    onControlEnd: function ({ id }) {
      setTimeout(() => {
        fetchControlEnd(id, 0)
          .then((res) => {
            console.log("停止控制")
          })
          .catch((err) => {
            console.error(err)
          })
      }, 500)
    },
    openMessage(e, msg) {
      const container = window.document.getElementById(`${e.cameraType}${e.cameraIndexCode}-audioControls`)
      var tipsDiv = window.document.createElement("div")
      tipsDiv.id = `${e.cameraType}${e.cameraIndexCode}-mobile-ez-ptz-container-tips`
      tipsDiv.style = "color:#ffffff;font-size: 20px;position:absolute;top:0;left: 50%;display: block;"
      tipsDiv.innerHTML = msg
      container.appendChild(tipsDiv)
      setTimeout(() => {
        var b = document.getElementById(`${e.cameraType}${e.cameraIndexCode}-mobile-ez-ptz-container-tips`)
        tipsDiv.style.display = "none"
      }, 1500)
    },
    onReset: function (item, index) {
      let id = item.id
      fetchMovePreset(id)
        .then((res) => {})
        .catch((err) => {})
      this.openMessage(item, "重置点位成功")
    },
    onSave: function (item, index) {
      let id = item.id
      fetchAddPreset(id)
        .then((res) => {})
        .catch((err) => {})
      this.openMessage(item, "收藏点位成功")
    },
  },
  directives: {
    ClickOutside,
  },
}
</script>

<style lang="less" scoped>
.monitor {
  width: 100%;
  height: 952px;
  position: relative;

  .screen {
    height: 100%;

    .wrap-empty {
      height: 865px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
    }

    .wrap {
      height: calc(100% - 52px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;

      img {
        width: 580px;
        height: 396px;
      }

      padding: 0px 15px 19px 15px;
      box-sizing: border-box;

      &-item {
      }

      .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        line-height: 38px;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .wrap {
      width: 100%;
      height: calc(100% - 52px);
      padding: 0px 15px 19px 15px;
      position: absolute;
      box-sizing: border-box;

      :last-child {
        margin-bottom: 0px;
      }
    }

    .dropdown {
      padding: 0 13px 0 12px;
      height: 36px;
      border: 4px;
      font-size: 14px;
      font-family: "SourceHanSansSC-Regular, SourceHanSansSC";
      font-weight: 400;
      color: #ffffff;
      background: linear-gradient(180deg, #57d5c7 0%, #409cfe 100%);

      &:hover {
        color: #ffffff;
      }

      img {
        margin-left: 13px;
      }
    }

    .proj {
      width: 100%;
      padding: 28px 0;
      box-sizing: border-box;

      &-name {
        text-align: center;
        font-size: 50px;
        font-family: "SourceHanSansCN-Heavy, SourceHanSansCN";
        font-weight: 800;
        color: #ffffff;
        line-height: 50px;
      }

      &-time {
        display: inline-block !important;
        left: 1151px !important;
        top: 56px !important;
      }

      &-list {
        float: right;
        margin-top: -46px;
        margin-right: 4px;

        &-btn {
          cursor: pointer;
          float: left;
          width: 28px;
          right: 35px;
          top: 0px;
          height: 27px;
          margin-left: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .menu {
      // margin-top: 50px;
      height: 68px;
      box-sizing: border-box;
      padding: 16px 0px 0px 16px;
      width: 100%;

      & > img {
        margin-left: 13px;
      }

      &-btn {
        cursor: pointer;
        display: inline-block;
        width: 36px;
        height: 36px;
        margin-right: 20px;
        border-radius: 4px;

        img {
          width: 100%;
          height: 100%;
        }

        &-tip {
          height: 10px;
        }
      }

      // .dropdown {
      //     position: absolute;
      //     margin-top: 0;
      //     margin-left: 10px;
      //     &-menu{
      //         width: 100%;
      //     }
      // }
      .select {
        height: 36px;
        background: rgba(6, 41, 57, 1);
        box-sizing: border-box;
        width: 114px;
        position: absolute;
        display: inline;
        margin-left: 10px;
        top: 17px;
        padding: 0 14px;

        &::before {
          position: absolute;
          content: "";
          height: 1px;
          top: 0;
          left: 0;
          right: 0;
          background: rgba(64, 213, 255, 1);
        }

        &::after {
          position: absolute;
          content: "";
          height: 1px;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(64, 213, 255, 1);
        }

        &-input {
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;

          input {
            width: 100%;
            flex: 1;
            user-select: none;
            background: rgba(6, 41, 57, 1);
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #40d5ff;
            cursor: pointer;
            text-overflow: ellipsis;
            /*去除阴影*/
            box-shadow: none;
            /*聚焦input的蓝色边框*/
            outline: none;
            /*textarea 禁止拖拽*/
            resize: none;
            /*去除边框*/
            border: none;
            /*常用于IOS下移除原生样式*/
            -webkit-appearance: none;
            /*点击高亮的颜色*/
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &::placeholder {
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #40d5ff;
            }
          }

          &.select--expanded {
            .list {
              opacity: 1;
              visibility: visible;
              transform: translateY(0%);
            }

            .icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      left: 0;
      z-index: 99999999999999999;
      margin-top: 8px;
      padding: 10px !important;
      background: #062939 !important;
      border-radius: 0 !important;
      border: 1px solid #40d5ff !important;
    }

    // .el-popper /deep/ .popper__arrow {
    //     // border-bottom-color: #1EBEF4 !important;
    //     // left: 50% !important;
    //     visibility: hidden;
    // }
    .unchoose {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-tit {
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        margin-bottom: 19px;
        color: #ffffff;
      }

      &-detail {
        white-space: nowrap;
        font-size: 21px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #c7c7c7;
      }
    }
  }
}

.border {
  position: absolute;
  content: "";
  width: 1px;
  top: 0;
  bottom: 0;
  background: rgba(64, 213, 255, 1);

  &.lt {
    left: 0;
  }

  &.rt {
    right: 0;
  }
}
.camera-title{
  font-size: 16px;
  padding: 4px 10px 4px 28px;
  line-height: 24px;
  position: relative;
  min-width: 240px;
  max-width: 600px;
  background: linear-gradient(270deg, rgba(93, 233, 255, 0.03) 0%, rgba(0, 119, 178, 0.89) 100%);
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  &::before {
    position: absolute;
    top: 12px;
    left: 10px;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(260deg, rgba(23, 255, 245, 0.94) 0%, rgba(0, 126, 255, 0.9) 100%);
  }
}
</style>
